// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  --ion-background-color: #1C1B1C;

  --ion-text-color: #ffffff;

	--ion-color-primary: #D40D53;
	--ion-color-primary-rgb: 212,13,83;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #bb0b49;
	--ion-color-primary-tint: #d82564;

	--ion-color-secondary: #840645;
	--ion-color-secondary-rgb: 132,6,84;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #74054a;
	--ion-color-secondary-tint: #901f65;
	--ion-color-secondary-half: #6b0739;
	--ion-color-secondary-transparent: #4f0429;

	--ion-color-tertiary: #F7E4EB;
	--ion-color-tertiary-rgb: 247,228,235;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #d9c9cf;
	--ion-color-tertiary-tint: #f8e7ed;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #1C1B1C;
	--ion-color-medium-rgb: 28,27,28;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #191819;
	--ion-color-medium-tint: #333233;

	--ion-color-light: #FFFFFF;
	--ion-color-light-rgb: 255,255,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #ffffff;

  --ion-tab-bar-background: #3F383B;
  --ion-tab-bar-color: #b4adb1;

  --ion-toolbar-background: #3F383B;
  --ion-toolbar-color: #ffffff;

}


@font-face {
  font-family: 'Roboto Medium';
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto Black';
  src: url('../assets/fonts/Roboto/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
}
