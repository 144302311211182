/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

@import "/src/theme/variables.scss";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/** Swiper use */
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@import "~quill/dist/quill.snow.css";

@font-face {
  font-family: 'Noto Music';
  src: url('assets/fonts/Noto_Music/NotoMusic-Regular.ttf') format('truetype');
}

@media only screen and (min-width: 768px) {
  .big-screen {
    display: flex;
  }
  .small-screen {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .big-screen {
    display: none;
  }
  .small-screen {
    display: flex;
  }
}

.modal-h100 {
  &::part(content) {
    height: 100px;
  }
}

.modal-h200 {
  &::part(content) {
    height: 200px;
  }
}

.modal-h250 {
  &::part(content) {
    height: 250px;
  }
}

.modal-h300 {
  &::part(content) {
    height: 300px;
  }
}

.transparent-modal {
  --background: #0000005c;
  backdrop-filter: blur(12px);

  &::part(backdrop) {
    backdrop-filter: blur(12px);
  }

  &::part(content) {
    width: 80%;
    height: 170px;
    margin: auto;
    border-radius: 10px;
  }
}

.transparent-modal-full-width {
  --background: #0000005c;
  backdrop-filter: blur(12px);

  &::part(backdrop) {
    backdrop-filter: blur(12px);
  }

}

.tempo-modal {
  --background: #0000005c;
  --height: auto;
  backdrop-filter: blur(12px);

  &::part(backdrop) {
    backdrop-filter: blur(12px);
  }

  &::part(content) {
    height: 400px;
    width: 200px;
    margin: auto;
    border-radius: 10px;
  }
}

.user-property-modal {
  --background: #0000005c;
  --height: auto;
  backdrop-filter: blur(12px);

  &::part(backdrop) {
    backdrop-filter: blur(12px);
  }

  &::part(content) {
    width: 80%;
    max-width: 300px;
//    height: 300px;
    margin: auto;
    border-radius: 10px;
  }
}

.user-qr-modal {
  --background: #0000005c;
  --height: auto;
  backdrop-filter: blur(12px);

  &::part(backdrop) {
    backdrop-filter: blur(12px);
  }

  &::part(content) {
    height: 300px;
    width: 250px;
    margin: auto;
    border-radius: 10px;
  }
}

.sheet-modal {
  backdrop-filter: blur(5px);
}

.player-footer-modal {
  --border-radius: 0;
  --border-width: 0;
  --width: 100%;
}

.ion-accordion-toggle-icon {
  color: var(--ion-color-primary) !important
}


ion-back-button {
  font-family: 'Roboto Regular';

  color: #b4adb1;
  --icon-padding-start: 1rem;
}

ion-back-button::part(icon) {
  color: var(--ion-color-primary);
}

ion-back-button::part(text) {
  font-size: 11px;
}

.back-button {
  font-size: 23px;
  --padding-start: 0;
}

ion-button {
  font-family: 'Roboto Medium';
  --border-radius: 0;
  text-transform: none;

//  --padding-top: 1.8em;
//  --padding-bottom: 1.8em;
}

.bright-checkbox {
  --border-radius: 0;
  --border-width: 0;
//  --border-color: var(--ion-color-secondary);
  --checkbox-background: var(--ion-color-tertiary);
  --checkbox-background-checked: var(--ion-color-secondary);
  --size: 26px;
  margin: 10px;
}

/*.bright-checkbox::part(container) {
  width: 30px;
  height: 30px;
}*/

.dark-checkbox {
  --border-width: 1px;
  --border-radius: 0;
  --border-color: var(--ion-color-light);
  --border-color-checked: var(--ion-color-primary);
  --checkbox-background: var(--ion-background-color);
  --checkbox-background-checked: var(--ion-color-primary);
  --checkmark-color: var(--ion-color-light);
  --checkmark-width: 2px;
  --size: 21px;
//  margin-right: 0;
}

/*.dark-checkbox::part(container) {
  width: 21px;
  height: 21px;
}*/

ion-item {
  --border-color: var(--ion-tab-bar-color);
}

ion-list-header {
  margin-left: 4.5rem;
  margin-top: 0;
  margin-bottom: 0;
}


ion-loading.band-on-loading {
  --background: #3F383B;
  --spinner-color: var(--ion-color-primary);

  color: var(--ion-color-primary);
}

/*ion-header {
  min-height: 10vh;
  background-color: #3F383B;

  ion-toolbar {
    position: absolute;
    bottom: 0;
  }
}*/

/*ion-toolbar {
  --border: 0;

  ion-title {
    font-family: 'Roboto Medium';
    font-weight: bold;
    font-size: large;
  }
}*/

ion-toolbar {
  --min-height: 45px;
}

ion-progress-bar {
  transform: scaleY(2);
  border-radius: 0;
  --background: var(--ion-color-secondary);
  --progress-background: var(--ion-color-primary);
}

ion-tab-bar {
  min-height: 8vh;
}

ion-tab-button {
  --padding-bottom: 0.5vh;
  --padding-top: 0.5vh;
  --padding-left: 1em;
  --padding-right: 1em;
}


ion-list-header {
  font-family: 'Roboto Black';
  --color: #FFFFFF;

  margin-top: 0em;
  margin-bottom: 0em;
}

ion-range {
  --bar-height: 5px;
  --bar-border-radius: 0;
  --bar-background: var(--ion-color-light);
  --bar-background-active: var(--ion-color-primary);
  --knob-background: var(--ion-color-primary);
  --knob-size: 20px;
  --knob-backgorund: var(--ion-color-primary);
}

ion-searchbar {
  --background: var(--ion-background-color);
  --border-radius: 0;
  --color: var(--ion-color-bright);
  --icon-color: var(--ion-color-primary);
  --cancel-button-color: var(--ion-color-primary);
  --box-shadow: 0;
}

ion-select::part(text) {
  color: var(--ion-color-bright);
}

ion-select::part(icon) {
  color: var(--ion-color-primary);
}

ion-select-option {
  color: var(--ion-color-bright);
}

ion-spinner {
  color: var(--ion-color-primary);
}

h1 {
  font-family: 'Roboto Medium';
  font-weight: bold;
//  font-size: 50pt;
}

h2 {
  font-family: 'Roboto Medium';
//  font-size: 45pt;
}

h3 {
  font-family: 'Roboto Medium';
//  font-size: 45pt;
  color: #b4adb1;
}

p {
  color: #b4adb1;
}

small {
  font-family: 'Roboto Regular';
  font-size: 9px;
}

.content-container {
  margin-top: 2vw;
  margin-left: 1vw;
  margin-right: 5vw;
}

.small-header {
  font-size: 11px;
  color: #b4adb1;
}

.close-icon {
  font-size: 40px;
}

.divider {
  height: 1px;
//  width: 100%;
  margin: 10px 0;
  background-color: #3F383B;
}

.alert-wrapper {
  --background: #3F383B;
  border-radius: 0;
  color: var(--ion-color-bright);
}

.band-on-alert {
  --background: #3F383B;
  border-radius: 0;
}

.band-on-alert .alert-wrapper {
  --background: #3F383B;
  --min-width: 50px;
  border-radius: 0;
  color: var(--ion-color-light);

  .alert-checkbox-label {
//    color: #b4adb1;
    color: var(--ion-color-light) !important;
  }

  .alert-radio-label {
//    color: #b4adb1 !important;
    color: var(--ion-color-light) !important;
  }

  .alert-message {
    color: var(--ion-color-light) !important;
  }
}

.band-on-popover {
  --background: #3F383B;
  --min-width: 50px;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.offline-toolbar {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-primary);
  padding-bottom: 0.2rem;
}

.slowCon-toolbar {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
  padding-bottom: 0.2rem;
}

.testserver-toolbar {
  background-color: blue;
  color: var(--ion-color-light);
  padding-bottom: 0.2rem;
}

ion-toast {
  --background: var(--ion-background-color);
  --color: var(--ion-color-light);
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/** Inputs **/
ion-input {
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: 'Roboto Medium';
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-secondary);
  --padding-top: 1.1em;
  --padding-bottom: 1.1em;
  --padding-start: 15px;
  --padding-end: 15px;
}

ion-input.white-helper.ios .input-bottom .helper-text,
ion-input.white-helper.ios .input-bottom .counter,
ion-input.white-helper.md .input-bottom .helper-text,
ion-input.white-helper.md .input-bottom .counter {
  color: var(--ion-color-light) !important;
  border-width: 0;
}


.dark-ion-input {
  --background: var(--ion-tab-bar-background);
  --padding-end: 10px !important;
  --padding-start: 10px !important;
  --padding-top: 6px;
  --padding-bottom: 6px;
  margin: 4px 0;
  min-height: 2.1rem;
}

/** Toolbar buttons **/
.ql-formats {
  color: var(--ion-color-light);
  stroke: var(--ion-color-light) !important;
}

/** Toolbar buttons **/
.ql-stroke {
  stroke: var(--ion-color-light) !important;
}

/** Toolbar buttons **/
.ql-picker-label {
  color: var(--ion-color-light);
}

/** Toolbar active buttons **/
.ql-active .ql-stroke {
  stroke: var(--ion-color-primary) !important;
}

.ql-container {
  width: 100%;
  background-color: var(--ion-tab-bar-background);
}

.ql-toolbar {
  background-color: var(--ion-tab-bar-background);
}

/** Editor element **/
.ql-editor {
  width: 100%;
  text-align: center;
  color: var(--ion-color-light);

  p {
    color: var(--ion-color-light);
  }
}

/** Placeholder **/
.ql-blank::before {
  color: gray !important;
}

/* Style for the dropdown menus (like font, size, etc.) */
.ql-toolbar .ql-picker {
//  color: #ffffff; /* Text color inside the dropdown */
//  background-color: #333333; /* Background color of the dropdown button */
}

.ql-toolbar .ql-picker-options {
  background-color: #444444; /* Background of the dropdown menu */
  border: 1px solid #666666; /* Border around the dropdown */
}

.ql-toolbar .ql-picker-options .ql-picker-item {
  color: #ffffff; /* Text color of each item in the dropdown */
}

.ql-toolbar .ql-picker-options .ql-picker-item:hover,
.ql-toolbar .ql-picker-options .ql-picker-item.ql-selected {
  background-color: #555555; /* Background color when an item is hovered or selected */
  color: #ffcc00; /* Text color when an item is hovered or selected */
}

/* Note Fonts */
.note-font {
  font-family: 'Noto Music', sans-serif;
  font-size: 20px;
  line-height: 20px;
}

/* Waveform Styling */
.band-on-waveform ::part(cursor) {
  background-color: white;
  width: 2px;
}

.band-on-waveform ::part(region) {
  border-radius: 0 !important;
//  overflow: hidden !important;
}

.band-on-waveform ::part(region-content) {
  overflow: hidden !important; /* Hides overflow content */
  white-space: nowrap !important; /* Prevents text from wrapping */
  text-overflow: ellipsis !important; /* Adds ellipsis (...) to indicate clipped text */
}

.band-on-waveform ::part(region-handle-right) {
  border-right-width: 0px !important;
  border-radius: 0 !important;
}

.band-on-waveform ::part(region-handle-left) {
  border-left-width: 0px !important;
  border-radius: 0 !important;
}

/* Arrangement Accordion */
.arrangement-accordion {
  .ion-accordion-toggle-icon {
    margin: 0;
  }
}

